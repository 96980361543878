var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/FooterClockItem/FooterClockItem.jinja"] = require( "front/components/FooterClockItem/FooterClockItem.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/components/Footer/Footer.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/components/Footer/Footer.jinja", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n";
env.getTemplate("front/components/FooterClockItem/FooterClockItem.jinja", false, "assets/app/front/components/Footer/Footer.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
if(Object.prototype.hasOwnProperty.call(t_4, "FooterClockItem")) {
var t_7 = t_4.FooterClockItem;
} else {
cb(new Error("cannot import 'FooterClockItem'")); return;
}
context.setVariable("FooterClockItem", t_7);
output += "\n\n";
var macro_t_8 = runtime.makeMacro(
[], 
["pages"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("pages", Object.prototype.hasOwnProperty.call(kwargs, "pages") ? kwargs["pages"] : {});var t_9 = "";t_9 += "\n    <div class=\"Footer\">\n        <div class=\"Footer-outter\">\n            <div class=\"Footer-overlay\"></div>\n            <div class=\"Footer-wrapper\">\n                <div class=\"Footer-top\">\n                    <div class=\"Footer-topTitle\">\n                        ";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "pages")),"footer")),"officesTitle"), env.opts.autoescape);
t_9 += "\n                    </div>\n                    <div class=\"Footer-topClocks\">\n    ";
t_9 += "\n    ";
t_9 += "\n    ";
t_9 += "\n    ";
t_9 += "\n    ";
t_9 += "\n                    </div>\n                </div>\n                <div class=\"Footer-bottom\">\n                    <div class=\"Footer-bottomNav\">\n                        <span><a href=\"";
t_9 += runtime.suppressValue((lineno = 22, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:index"])), env.opts.autoescape);
t_9 += "\" class=\"u-Route\">true</a></span>\n                        <span><a href=\"";
t_9 += runtime.suppressValue((lineno = 23, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:search"])), env.opts.autoescape);
t_9 += "\" class=\"u-Route\">search</a></span>\n                        <span><a href=\"";
t_9 += runtime.suppressValue((lineno = 24, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:technologies"])), env.opts.autoescape);
t_9 += "\" class=\"u-Route\">technologies</a></span>\n                        <span><a href=\"";
t_9 += runtime.suppressValue((lineno = 25, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:development"])), env.opts.autoescape);
t_9 += "\" class=\"u-Route\">development</a></span>\n                    </div>\n                    <div class=\"Footer-bottomNav\">\n                        <span><a href=\"";
t_9 += runtime.suppressValue((lineno = 28, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:team"])), env.opts.autoescape);
t_9 += "\" class=\"u-Route\">team</a></span>\n                        <span><a href=\"";
t_9 += runtime.suppressValue((lineno = 29, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:our-story"])), env.opts.autoescape);
t_9 += "\" class=\"u-Route\">our story</a></span>\n                        <span><a href=\"";
t_9 += runtime.suppressValue((lineno = 30, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:news"])), env.opts.autoescape);
t_9 += "\" class=\"u-Route\">news</a></span>\n                        <span><a href=\"";
t_9 += runtime.suppressValue((lineno = 31, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:join"])), env.opts.autoescape);
t_9 += "\" class=\"u-Route\">join</a></span>\n                    </div>\n                    <div class=\"Footer-bottomNav\">\n                        <span><a href=\"";
t_9 += runtime.suppressValue((lineno = 34, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:contact"])), env.opts.autoescape);
t_9 += "\" class=\"u-Route\">contact</a></span>\n                        ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "pages")),"footer")),"linkedin")) {
t_9 += "<span><a href=\"";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "pages")),"footer")),"linkedin"), env.opts.autoescape);
t_9 += "\">linkedin</a></span>";
;
}
t_9 += "\n                        ";
if(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "pages")),"footer")),"twitter")) {
t_9 += "<span><a href=\"";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "pages")),"footer")),"twitter"), env.opts.autoescape);
t_9 += "\">twitter</a></span>";
;
}
t_9 += "\n                        <span><a href=\"https://privacy.trueplatform.com/\" target=\"_blank\" rel=\"nofollow noopener noreferrer\">privacy policy</a></span>\n                        <span><a href=\"";
t_9 += runtime.suppressValue((lineno = 42, colno = 45, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "url"), "url", context, ["front:opt-out-guide"])), env.opts.autoescape);
t_9 += "\" id=\"optOutGuide\" class=\"u-Route\">opt-out guide</a></span>\n                    </div>\n                    <div class=\"Footer-bottomNav\">\n                        ";
t_9 += runtime.suppressValue(env.getFilter("safe").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "pages")),"footer")),"address")), env.opts.autoescape);
t_9 += "\n                        <span><a href=\"tel:";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "pages")),"footer")),"phone"), env.opts.autoescape);
t_9 += "\">";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "pages")),"footer")),"phone"), env.opts.autoescape);
t_9 += "</a></span>\n                        <span><a href=\"mailto:";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "pages")),"footer")),"email"), env.opts.autoescape);
t_9 += "\">";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "pages")),"footer")),"email"), env.opts.autoescape);
t_9 += "</a></span>\n                    </div>\n                    <div class=\"Footer-bottomCopyright\">\n                        <span>&copy; ";
t_9 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "pages")),"currentYear"), env.opts.autoescape);
t_9 += " all rights reserved</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_9);
});
context.addExport("Footer");
context.setVariable("Footer", macro_t_8);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/components/Footer/Footer.jinja"] , dependencies)