var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["front/pages/Section/Section.jinja"] = require( "front/pages/Section/Section.jinja" );
dependencies["front/utils/macros.jinja"] = require( "front/utils/macros.jinja" );
dependencies["front/components/CommonBlock/CommonBlock.jinja"] = require( "front/components/CommonBlock/CommonBlock.jinja" );




var shim = require("/var/www/django/true.astroshock.ru/application/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/front/pages/SearchOffer/SearchOfferSection.jinja"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "\n";
env.getTemplate("front/pages/Section/Section.jinja", true, "assets/app/front/pages/SearchOffer/SearchOfferSection.jinja", false, function(t_3,t_2) {
if(t_3) { cb(t_3); return; }
parentTemplate = t_2
for(var t_1 in parentTemplate.blocks) {
context.addBlock(t_1, parentTemplate.blocks[t_1]);
}
output += "\n";
env.getTemplate("front/utils/macros.jinja", false, "assets/app/front/pages/SearchOffer/SearchOfferSection.jinja", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("macros", t_4);
output += "\n\n";
env.getTemplate("front/components/CommonBlock/CommonBlock.jinja", false, "assets/app/front/pages/SearchOffer/SearchOfferSection.jinja", false, function(t_8,t_7) {
if(t_8) { cb(t_8); return; }
t_7.getExported(function(t_9,t_7) {
if(t_9) { cb(t_9); return; }
if(Object.prototype.hasOwnProperty.call(t_7, "CommonBlock")) {
var t_10 = t_7.CommonBlock;
} else {
cb(new Error("cannot import 'CommonBlock'")); return;
}
context.setVariable("CommonBlock", t_10);
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("title"))(env, context, frame, runtime, function(t_12,t_11) {
if(t_12) { cb(t_12); return; }
output += t_11;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("social"))(env, context, frame, runtime, function(t_14,t_13) {
if(t_14) { cb(t_14); return; }
output += t_13;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content_class"))(env, context, frame, runtime, function(t_16,t_15) {
if(t_16) { cb(t_16); return; }
output += t_15;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("content"))(env, context, frame, runtime, function(t_18,t_17) {
if(t_18) { cb(t_18); return; }
output += t_17;
output += "\n\n";
(parentTemplate ? function(e, c, f, r, cb) { cb(""); } : context.getBlock("data"))(env, context, frame, runtime, function(t_20,t_19) {
if(t_20) { cb(t_20); return; }
output += t_19;
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})})})})})})})})})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_title(env, context, frame, runtime, cb) {
var lineno = 6;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "true • search: position";
if(runtime.contextOrFrameLookup(context, frame, "position")) {
output += " — ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"title"), env.opts.autoescape);
;
}
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_social(env, context, frame, runtime, cb) {
var lineno = 8;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    <meta name=\"description\" content=\"\"/>\n\n    <meta property=\"og:type\" content=\"website\"/>\n    <meta property=\"og:image\" content=\"";
output += runtime.suppressValue((lineno = 12, colno = 68, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"build_absolute_uri"), "request[\"build_absolute_uri\"]", context, [(lineno = 12, colno = 75, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/headers/bg-search-team.jpg"]))])), env.opts.autoescape);
output += "\"/>\n    <meta property=\"og:image:width\" content=\"1440\"/>\n    <meta property=\"og:image:height\" content=\"640\"/>\n    <meta property=\"og:url\" content=\"";
output += runtime.suppressValue((lineno = 15, colno = 66, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "request")),"build_absolute_uri"), "request[\"build_absolute_uri\"]", context, [])), env.opts.autoescape);
output += "\">\n    <meta property=\"og:title\" content=\"true • search: position";
if(runtime.contextOrFrameLookup(context, frame, "position")) {
output += " — ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"title"), env.opts.autoescape);
;
}
output += "\"/>\n    <meta property=\"og:description\" content=\"\"/>\n";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content_class(env, context, frame, runtime, cb) {
var lineno = 20;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "SearchPage OfferPage SearchExtraPage";
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_content(env, context, frame, runtime, cb) {
var lineno = 22;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
output += "\n    ";
if(!runtime.contextOrFrameLookup(context, frame, "auth")) {
output += "<div class=\"SearchOfferSection SearchOfferPassword\">\n            <div class=\"SearchOfferPassword-outter\">\n                <div class=\"SearchOfferPassword-img\">\n                    <img src=\"";
output += runtime.suppressValue((lineno = 27, colno = 39, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["img/headers/bg-search-invite.jpg"])), env.opts.autoescape);
output += "\">\n                </div>\n                <div class=\"SearchOfferPassword-wrapper\">\n                    <form method=\"POST\" action=\"\" class=\"SearchOfferPassword-info\">\n                        <div class=\"SearchOfferPassword-infoText\">\n                            This content is password protected.<br>To view it please enter your password below:\n                        </div>\n                        <div class=\"SearchOfferPassword-infoInput\">\n                            <input type=\"password\" name=\"password\" placeholder=\"password\">\n                        </div>\n                        <div class=\"SearchOfferPassword-message\">invalid password, please try again</div>\n                        <button class=\"SearchOfferPassword-infoBtn\" type=\"submit\">Submit</button>\n                    </form>\n                </div>\n            </div>\n        </div>\n    ";
;
}
if(runtime.contextOrFrameLookup(context, frame, "auth")) {
output += "<div class=\"SearchOfferSection PageSection ";
output += "\">\n            <div class=\"SearchOfferSection-wrapper NeedFixOnPopup\">\n                <div class=\"Content-outter isInitialState\">\n                    <div class=\"Content-wrapper\">\n                        <div class=\"SearchOfferSection-headerPrint\">\n                            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"logo")) {
output += "\n                                <div class=\"SearchOfferSection-headerInfoBlockImg\">\n                                    <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"logo")),"w218_url"), env.opts.autoescape);
output += "\"\n                                         srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"logo")),"w253_url"), env.opts.autoescape);
output += " 1920w,\n                                                  ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"logo")),"w218_url"), env.opts.autoescape);
output += " 1680w,\n                                                  ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"logo")),"w137_url"), env.opts.autoescape);
output += " 1120w,\n                                                  ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"logo")),"w360_url"), env.opts.autoescape);
output += " 720w\"\n                                         sizes=\"(max-width: 1119px) 1, ";
output += "\n                                                 75%\" ";
output += "\n                                         alt=\"\">\n                                </div>\n                            ";
;
}
output += "\n                            <div class=\"SearchOfferSection-headerPrintTrue\">true</div>\n                        </div>\n                        <div class=\"SearchOfferSection-header\">\n                            <div class=\"SearchOfferSection-headerTitle\">\n                                ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"title"), env.opts.autoescape);
output += "\n                                <div class=\"SearchOfferSection-headerTitlePrint\">\n                                    ";
output += runtime.suppressValue((lineno = 69, colno = 60, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-print",runtime.makeKeywordArgs({"caller": (function (){var macro_t_21 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_22 = "";;
frame = frame.pop();
return new runtime.SafeString(t_22);
});
return macro_t_21;})()})])), env.opts.autoescape);
output += " Print\n                                </div>\n                            </div>\n                            <div class=\"SearchOfferSection-headerInfo\">\n                                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"logo")) {
output += "\n                                    <div class=\"SearchOfferSection-headerInfoBlock SearchOfferSection-headerInfoBlock--logo\">\n                                        <div class=\"SearchOfferSection-headerInfoBlockImg\">\n                                            <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"logo")),"w218_url"), env.opts.autoescape);
output += "\"\n                                                 srcset=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"logo")),"w253_url"), env.opts.autoescape);
output += " 1920w,\n                                                  ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"logo")),"w218_url"), env.opts.autoescape);
output += " 1680w,\n                                                  ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"logo")),"w137_url"), env.opts.autoescape);
output += " 1120w,\n                                                  ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"logo")),"w360_url"), env.opts.autoescape);
output += " 720w\"\n                                                 sizes=\"(max-width: 1119px) 1, ";
output += "\n                                                 75%\" ";
output += "\n                                                 alt=\"\">\n                                        </div>\n                                    </div>\n                                ";
;
}
output += "\n                                <div class=\"SearchOfferSection-headerInfoTextWrapper\">\n                                    <div class=\"SearchOfferSection-headerInfoBlock SearchOfferSection-headerInfoBlock--socMob\">\n                                        <div class=\"SearchOfferSection-headerInfoBlockBody\">\n                                            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"facebook")) {
output += "<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"facebook"), env.opts.autoescape);
output += "\" target=\"_blank\">\n                                                    ";
output += runtime.suppressValue((lineno = 92, colno = 76, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-fb",runtime.makeKeywordArgs({"caller": (function (){var macro_t_23 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_24 = "";;
frame = frame.pop();
return new runtime.SafeString(t_24);
});
return macro_t_23;})()})])), env.opts.autoescape);
output += "\n                                                </a>\n                                            ";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"twitter")) {
output += "<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"twitter"), env.opts.autoescape);
output += "\" target=\"_blank\">\n                                                    ";
output += runtime.suppressValue((lineno = 97, colno = 76, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-twitter",runtime.makeKeywordArgs({"caller": (function (){var macro_t_25 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_26 = "";;
frame = frame.pop();
return new runtime.SafeString(t_26);
});
return macro_t_25;})()})])), env.opts.autoescape);
output += "\n                                                </a>\n                                            ";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"instagram")) {
output += "<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"instagram"), env.opts.autoescape);
output += "\" target=\"_blank\">\n                                                    ";
output += runtime.suppressValue((lineno = 102, colno = 76, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-instagram",runtime.makeKeywordArgs({"caller": (function (){var macro_t_27 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_28 = "";;
frame = frame.pop();
return new runtime.SafeString(t_28);
});
return macro_t_27;})()})])), env.opts.autoescape);
output += "\n                                                </a>\n                                            ";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"linkedin")) {
output += "<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"linkedin"), env.opts.autoescape);
output += "\" target=\"_blank\">\n                                                    ";
output += runtime.suppressValue((lineno = 107, colno = 76, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-linkedin",runtime.makeKeywordArgs({"caller": (function (){var macro_t_29 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_30 = "";;
frame = frame.pop();
return new runtime.SafeString(t_30);
});
return macro_t_29;})()})])), env.opts.autoescape);
output += "\n                                                </a>\n                                            ";
;
}
output += "</div>\n\n                                    </div>\n                                    <div class=\"SearchOfferSection-headerInfoBlock SearchOfferSection-headerInfoBlock--website\">\n                                        <div class=\"SearchOfferSection-headerInfoBlockTitle\">Website:</div>\n                                        <div class=\"SearchOfferSection-headerInfoBlockBody\">\n                                            <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"website"), env.opts.autoescape);
output += "\"\n                                               target=\"_blank\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"website"), env.opts.autoescape);
output += "</a>\n                                        </div>\n                                    </div>\n                                    <div class=\"SearchOfferSection-headerInfoBlock SearchOfferSection-headerInfoBlock--location\">\n                                        <div class=\"SearchOfferSection-headerInfoBlockTitle\">Location:</div>\n                                        <div class=\"SearchOfferSection-headerInfoBlockBody\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"location"), env.opts.autoescape);
output += "</div>\n                                    </div>\n                                    <div class=\"SearchOfferSection-headerInfoBlock SearchOfferSection-headerInfoBlock--socials\">\n                                        <div class=\"SearchOfferSection-headerInfoBlockTitle\">Company profile:</div>\n                                        <div class=\"SearchOfferSection-headerInfoBlockBody SearchOfferSection-headerInfoBlockBody--social\">\n                                            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"facebook")) {
output += "<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"facebook"), env.opts.autoescape);
output += "\" target=\"_blank\">\n                                                    ";
output += runtime.suppressValue((lineno = 129, colno = 76, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-fb",runtime.makeKeywordArgs({"caller": (function (){var macro_t_31 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_32 = "";;
frame = frame.pop();
return new runtime.SafeString(t_32);
});
return macro_t_31;})()})])), env.opts.autoescape);
output += "\n                                                </a>\n                                            ";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"twitter")) {
output += "<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"twitter"), env.opts.autoescape);
output += "\" target=\"_blank\">\n                                                    ";
output += runtime.suppressValue((lineno = 134, colno = 76, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-twitter",runtime.makeKeywordArgs({"caller": (function (){var macro_t_33 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_34 = "";;
frame = frame.pop();
return new runtime.SafeString(t_34);
});
return macro_t_33;})()})])), env.opts.autoescape);
output += "\n                                                </a>\n                                            ";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"instagram")) {
output += "<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"instagram"), env.opts.autoescape);
output += "\" target=\"_blank\">\n                                                    ";
output += runtime.suppressValue((lineno = 139, colno = 76, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-instagram",runtime.makeKeywordArgs({"caller": (function (){var macro_t_35 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_36 = "";;
frame = frame.pop();
return new runtime.SafeString(t_36);
});
return macro_t_35;})()})])), env.opts.autoescape);
output += "\n                                                </a>\n                                            ";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"linkedin")) {
output += "<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"linkedin"), env.opts.autoescape);
output += "\" target=\"_blank\">\n                                                    ";
output += runtime.suppressValue((lineno = 144, colno = 76, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svgSprite"), "macros[\"svgSprite\"]", context, ["icon-linkedin",runtime.makeKeywordArgs({"caller": (function (){var macro_t_37 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_38 = "";;
frame = frame.pop();
return new runtime.SafeString(t_38);
});
return macro_t_37;})()})])), env.opts.autoescape);
output += "\n                                                </a>\n                                            ";
;
}
output += "</div>\n\n                                        <div class=\"SearchOfferSection-headerInfoBlockBody--socialPrint\">\n                                            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"facebook")) {
output += "<span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"facebook"), env.opts.autoescape);
output += "</span>\n                                            ";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"twitter")) {
output += "<span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"twitter"), env.opts.autoescape);
output += "</span>\n                                            ";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"instagram")) {
output += "<span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"instagram"), env.opts.autoescape);
output += "</span>\n                                            ";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"linkedin")) {
output += "<span>";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"linkedin"), env.opts.autoescape);
output += "</span>\n                                            ";
;
}
output += "</div>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n\n                        <div class=\"SearchOfferSection-body\">\n                            <div class=\"SearchOfferSection-sidebar\">\n                                <div class=\"SearchOfferSection-sidebarTeam\">\n\n                                    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"investors")) {
output += "<div class=\"SearchOfferSection-sidebarTeamInvestors\">\n                                        <div class=\"SearchOfferSection-sidebarTeamInvestorsTitle\">Investors:</div>\n                                        <div class=\"SearchOfferSection-sidebarTeamInvestorsBody\">\n                                            ";
frame = frame.push();
var t_41 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"investors");
if(t_41) {t_41 = runtime.fromIterator(t_41);
var t_40 = t_41.length;
for(var t_39=0; t_39 < t_41.length; t_39++) {
var t_42 = t_41[t_39];
frame.set("inv", t_42);
frame.set("loop.index", t_39 + 1);
frame.set("loop.index0", t_39);
frame.set("loop.revindex", t_40 - t_39);
frame.set("loop.revindex0", t_40 - t_39 - 1);
frame.set("loop.first", t_39 === 0);
frame.set("loop.last", t_39 === t_40 - 1);
frame.set("loop.length", t_40);
output += "\n                                                ";
if(runtime.memberLookup((t_42),"website")) {
output += "<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((t_42),"website"), env.opts.autoescape);
output += "\" target=\"_blank\">";
output += runtime.suppressValue(runtime.memberLookup((t_42),"title"), env.opts.autoescape);
output += "</a>\n                                                ";
;
}
else {
output += "<span>";
output += runtime.suppressValue(runtime.memberLookup((t_42),"title"), env.opts.autoescape);
output += "</span>\n                                                ";
;
}
;
}
}
frame = frame.pop();
output += "\n                                        </div>\n                                    </div>\n                                    ";
;
}
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"team")) {
output += "<div class=\"SearchOfferSection-sidebarTeamSearch\">\n                                        <div class=\"SearchOfferSection-sidebarTeamTitle\">Search Team:</div>\n                                        <div class=\"SearchOfferSection-sidebarTeamList\">\n                                            ";
frame = frame.push();
var t_45 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"team");
if(t_45) {t_45 = runtime.fromIterator(t_45);
var t_44 = t_45.length;
for(var t_43=0; t_43 < t_45.length; t_43++) {
var t_46 = t_45[t_43];
frame.set("item", t_46);
frame.set("loop.index", t_43 + 1);
frame.set("loop.index0", t_43);
frame.set("loop.revindex", t_44 - t_43);
frame.set("loop.revindex0", t_44 - t_43 - 1);
frame.set("loop.first", t_43 === 0);
frame.set("loop.last", t_43 === t_44 - 1);
frame.set("loop.length", t_44);
output += "\n                                                <div class=\"SearchOfferSection-sidebarTeamItem\">\n                                                    <div class=\"SearchOfferSection-sidebarTeamItemName\">";
output += runtime.suppressValue(runtime.memberLookup((t_46),"name"), env.opts.autoescape);
output += "</div>\n                                                    <div class=\"SearchOfferSection-sidebarTeamItemPosition\">";
output += runtime.suppressValue(runtime.memberLookup((t_46),"title"), env.opts.autoescape);
output += "</div>\n                                                    <a href=\"mailto:";
output += runtime.suppressValue(runtime.memberLookup((t_46),"email"), env.opts.autoescape);
output += "\"\n                                                       class=\"SearchOfferSection-sidebarTeamItemEmail\">";
output += runtime.suppressValue(runtime.memberLookup((t_46),"email"), env.opts.autoescape);
output += "</a>\n                                                    <a href=\"tel:";
output += runtime.suppressValue(runtime.memberLookup((t_46),"phone"), env.opts.autoescape);
output += "\"\n                                                       class=\"SearchOfferSection-sidebarTeamItemPhone\">";
output += runtime.suppressValue(runtime.memberLookup((t_46),"phone"), env.opts.autoescape);
output += "</a>\n                                                </div>\n                                            ";
;
}
}
frame = frame.pop();
output += "\n                                        </div>\n                                    </div>\n                                    ";
;
}
output += "</div>\n                            </div>\n                            <div class=\"SearchOfferSection-content\">\n                                ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"blocks"))) {
output += "\n                                    <div class=\"SearchOfferSection-blocks\">\n                                        <div class=\"SearchOfferSection-blocksWrapper\">\n                                            ";
frame = frame.push();
var t_49 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "position")),"blocks");
if(t_49) {t_49 = runtime.fromIterator(t_49);
var t_48 = t_49.length;
for(var t_47=0; t_47 < t_49.length; t_47++) {
var t_50 = t_49[t_47];
frame.set("block", t_50);
frame.set("loop.index", t_47 + 1);
frame.set("loop.index0", t_47);
frame.set("loop.revindex", t_48 - t_47);
frame.set("loop.revindex0", t_48 - t_47 - 1);
frame.set("loop.first", t_47 === 0);
frame.set("loop.last", t_47 === t_48 - 1);
frame.set("loop.length", t_48);
output += "\n                                                <div class=\"SearchOfferSection-blocksBlock SearchOfferSection-blocksBlock--";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0"), env.opts.autoescape);
output += "\">\n                                                    ";
output += runtime.suppressValue((lineno = 212, colno = 66, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "CommonBlock"), "CommonBlock", context, [t_50,runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index0")])), env.opts.autoescape);
output += "\n                                                </div>\n                                            ";
;
}
}
frame = frame.pop();
output += "\n                                        </div>\n                                    </div>\n                                ";
;
}
output += "\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n            <div class=\"Section-content\">\n                ";
context.getBlock("section_content")(env, context, frame, runtime, function(t_52,t_51) {
if(t_52) { cb(t_52); return; }
output += t_51;
output += "\n            </div>\n        </div>\n    ";
});
}
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_section_content(env, context, frame, runtime, cb) {
var lineno = 224;
var colno = 19;
var output = "";
try {
var frame = frame.push(true);
cb(null, output);
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
function b_data(env, context, frame, runtime, cb) {
var lineno = 230;
var colno = 3;
var output = "";
try {
var frame = frame.push(true);
context.getSuper(env, "data", b_data, frame, runtime, function(t_53,hole_101) {
if(t_53) { cb(t_53); return; }
hole_101 = runtime.markSafe(hole_101);
output += "\n    ";
output += runtime.suppressValue(hole_101, env.opts.autoescape);
output += "\n\n    window.app.data.position = ";
if(runtime.contextOrFrameLookup(context, frame, "auth")) {
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("jsonify").call(context, runtime.contextOrFrameLookup(context, frame, "position"))), env.opts.autoescape);
;
}
else {
output += "{}";
;
}
output += ";\n";
cb(null, output);
});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
b_title: b_title,
b_social: b_social,
b_content_class: b_content_class,
b_content: b_content,
b_section_content: b_section_content,
b_data: b_data,
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/front/pages/SearchOffer/SearchOfferSection.jinja"] , dependencies)